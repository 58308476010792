<script setup>

import FormField from '@/components/FormField'
import { computed, inject, reactive, ref } from 'vue'

const AppSettings = inject('AppSettings')
const HCP = inject('HCP')

const changePassword = reactive({
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
})

const changePasswordValid = computed(() =>
    changePassword.oldPassword.length > 3 &&
    changePassword.newPassword.length > 3 &&
    changePassword.newPassword == changePassword.newPasswordConfirm)

const changePasswordError = ref(null)

const changePasswordApi = async () => {
    const status = await HCP.setMyPassword(changePassword)

    if (status == 'failure')
        changePasswordError.value = 'Password change failed'
}

const changePasswordClearError = () => {
    changePasswordError.value = null
}

</script>
<template>
    <form>
        <fieldset class="border p-2">
            <legend class="float-none w-auto p-2">API</legend>
            <FormField label="API Base URL" fieldId="apiBaseUrl" v-model="AppSettings.api_base_url"/>
        </fieldset>
        <fieldset>
            <legend class="float-none w-auto p-2">Authentication</legend>
            <FormField label="Username" fieldId="username" v-model="AppSettings.username"/>
            <FormField label="Password" fieldId="password" fieldType="password" v-model="AppSettings.password"/>
            <button type="button" class="btn btn-success" v-if="HCP.apiAuth.value" @click="HCP.authAndConnect(false)">Authenticate</button>
            <button type="button" class="btn btn-primary" v-else @click="HCP.authAndConnect(true)">Refresh Authentication</button>
        </fieldset>
        <fieldset class="border p-2">
            <legend class="float-none w-auto p-2">MQTT Settings</legend>
            <FormField label="IP Address/Host" fieldId="mqttHost" v-model="AppSettings.mqtt_options.host"/>
            <FormField label="Port" fieldId="mqttPort" fieldType="number" v-model="AppSettings.mqtt_options.port"/>
            <FormField label="WS Path" fieldId="mqttPath" v-model="AppSettings.mqtt_options.path"/>
            <button type="button" class="btn btn-success" :disabled="HCP.mqttAuth.value" @click="HCP.connect">Connect</button>
        </fieldset>
        <button type="button" class="btn btn-primary chpass collapse show"
                data-bs-toggle="collapse" data-bs-target=".chpass">Change My Password</button>
        <fieldset class="border p-2 collapse chpass">
            <legend class="float-none w-auto p-2">Change Password</legend>
            <div v-if="changePasswordError" class="alert alert-danger" role="alert">
                {{ changePasswordError }}
                <button type="button" class="btn-close" @click="changePasswordClearError"></button>
            </div>
            <FormField label="Old Password" fieldId="oldPassword" fieldType="password" v-model="changePassword.oldPassword"/>
            <FormField label="New Password" fieldId="newPassword" fieldType="password" v-model="changePassword.newPassword"/>
            <FormField label="Confirm New Password" fieldId="newPasswordConfirm" fieldType="password" v-model="changePassword.newPasswordConfirm"/>
            <button type="button" class="btn btn-success" :disabled="!changePasswordValid" @click="changePasswordApi">Set New Password</button>
        </fieldset>
    </form>
</template>
