import { createApp, reactive, ref, watch } from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import { APIError, HomeControlPanel } from './controlpanel'

const app = createApp(App)
const localStorage = window.localStorage

const getMqttClientId = (refresh) => {
    const session_id = Math.random().toString(16).substring(2, 6)
    let client_id = refresh ? null : localStorage.getItem('MQTT_clientid')

    if (!client_id) {
        client_id = Math.random().toString(16).substring(2, 6)
        localStorage.setItem('MQTT_clientid', client_id)
    }
    return `HCP_App_${client_id}_${session_id}`
}

const defaultApiBaseUrl = (location) => {
    let baseUrl = location.protocol
    baseUrl += '//'
    baseUrl += location.hostname
    if (location.port) {
        baseUrl += ':'
        baseUrl += location.port
    }
    baseUrl += '/api'
    return baseUrl
}

const api_base_url = ref(localStorage.getItem('API_url') || defaultApiBaseUrl(window.location))
watch(api_base_url, (value) => localStorage.setItem('API_url', value))
const auth_token = ref(localStorage.getItem('token'))
watch(auth_token, (value) => localStorage.setItem('token', value))
const protocol = ref(localStorage.getItem('MQTT_protocol') || 'wss')
watch(protocol, (value) => localStorage.setItem('MQTT_protocol', value))
const host = ref(localStorage.getItem('MQTT_host') || '192.168.1.1')
watch(host, (value) => localStorage.setItem('MQTT_host', value))
const port = ref(parseInt(localStorage.getItem('MQTT_port') || 9001))
watch(port, (value) => localStorage.setItem('MQTT_port', value))
const path = ref(localStorage.getItem('MQTT_path') || '')
watch(path, (value) => localStorage.setItem('MQTT_path', value))
const username = ref(localStorage.getItem('AUTH_username') || 'username')
watch(username, (value) => localStorage.setItem('AUTH_username', value))
const password = ref(localStorage.getItem('AUTH_password') || 'password')
watch(password, (value) => localStorage.setItem('AUTH_password', value))
const expire_device = ref(parseInt(localStorage.getItem('expire_device') || 86400))
watch(expire_device, (value) => localStorage.setItem('expire_device', value))

const AppSettings = reactive({
    api_base_url,
    auth_token,
    expire_device, 
    username,
    password,
    mqtt_options: {
        protocol, host, port, path,
        clientId: getMqttClientId(),
        // log: console.log,
        // createWebsocket: (url, protocols) => {
        //     console.log(`URL: ${url} -> ${protocols}`)
        //     return new WebSocket(url, protocols)
        // }
        manualConnect: true
        }})

const HCP = new HomeControlPanel(AppSettings)

app.provide('getMqttClientId', getMqttClientId)
app.provide('AppSettings', AppSettings)
app.provide('HCP', HCP)

HCP.authAndConnect().then(
    () => console.log('Authentication complete'),
    (err) => {
        if (err instanceof APIError)
            console.error(`HTTP Authentication failed with status: ${err.status}`)
        else
            console.error(`Authentication failed: ${err.message}`)
    }
)

app.use(router).mount('#app')
