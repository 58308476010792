<script setup>

import { defineProps } from 'vue'

const props = defineProps({
    long: {type: String, default: ''},
    short: {type: String, default: ''}
})

</script>
<template>
    <span class="d-none d-lg-inline">{{ props.long }}<slot name="long"></slot></span>
    <span class="d-lg-none">{{ props.short }}<slot name="short"></slot></span>
</template>
