<script setup>

import { computed, defineModel, defineProps } from 'vue'
import { onMounted, onUnmounted } from 'vue'

import FormField from '@/components/FormField'
import ShowDateTimeDisplay from '@/components/ShowDateTimeDisplay.vue'
import ShowTextDisplay from '@/components/ShowTextDisplay.vue'
import { APIPaginator } from '@/controlpanel'

const props = defineProps({
    logs: {type: APIPaginator, required: true}
})

const ShowLogQuery = defineModel()

onMounted(() => {
    document.body.classList.add('modal-open')
})

onUnmounted(() => {
    document.body.classList.remove('modal-open')
})

const showState = (log, mobile) => {
    switch (log.state) {
    case 'locked':
        return mobile ? 'Lock' : 'Locked'
    case 'unlocked':
        return mobile ? 'Unlock' : 'Unlocked'
    case 'not_fully_locked':
        return mobile ? 'Failure' : 'Lock Failure'
    }
    return ''
}

const showAction = (log, mobile) => {
    switch (log.action) {
    case 'unknown':
        return mobile ? 'Unk' : 'Unknown'
    case 'lock':
    case 'manual_lock':
    case 'schedule_lock':
    case 'auto_lock':
    case 'key_lock':
    case 'one_touch_lock':
        return 'Lock'
    case 'unlock':
    case 'manual_unlock':
    case 'schedule_unlock':
    case 'key_unlock':
        return 'Unlock'
    case 'lock_failure_invalid_pin_or_id':
    case 'lock_failure_invalid_schedule':
    case 'unlock_failure_invalid_pin_or_id':
    case 'unlock_failure_invalid_schedule':
        return 'Failure'
    case 'non_access_user_operational_even':
        return mobile ? 'Oper' : 'Operational'
    }
    return ''
}

const showBattery = (log) => {
    const battery = parseInt(log.battery)

    if (isNaN(battery))
        return "Not Available"

    return `${battery}%`
}

const showLinkQuality = (log) => {
    const linkquality = parseInt(log.linkquality)

    if (isNaN(linkquality))
        return "Not Available"

    const percent = parseInt((linkquality / 255) * 100)

    return `${linkquality} (${percent}%)`
}

const closeModal = () => {
    ShowLogQuery.value.start = null
    ShowLogQuery.value.end = null
    ShowLogQuery.value.deviceId = null
}

const queryStart = computed({
    get() {
        if (ShowLogQuery.value.start instanceof Date)
            return ShowLogQuery.value.start.toISOString().slice(0, 19)
        return ShowLogQuery.value.start
    },
    set(dateString) {
        ShowLogQuery.value.start = new Date(dateString)
    }
})

const queryEnd = computed({
    get() {
        if (ShowLogQuery.value.end instanceof Date)
            return ShowLogQuery.value.end.toISOString().slice(0, 19)
        return ShowLogQuery.value.end
    },
    set(dateString) {
        ShowLogQuery.value.end = new Date(dateString)
    }
})

</script>
<template>
<Teleport to="body">
    <div class="modal show" id="doorLogModal" tabindex="-1" role="dialog"
         aria-labelledby="doorLogModalLabel" style="display: block;">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="doorLogModalLabel">Door Log Entries</h5>
                    <button type="button" class="btn-close"
                            aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <form v-if="props.logs">
                        <fieldset class="border p-2">
                            <legend class="float-none w-auto p-2">Logs Time Range</legend>
                            <FormField label="Start at" fieldId="start"
                                       fieldType="datetime-local" v-model="queryStart"/>
                            <FormField label="End at" fieldId="end"
                                       fieldType="datetime-local" v-model="queryEnd"/>
                        </fieldset>
                    </form>
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th><span class="d-none d-lg-inline">Current </span>State</th>
                                <th>Action<span class="d-none d-lg-inline"> Taken</span></th>
                                <th class="d-none d-sm-table-cell">Battery Level</th>
                                <th class="d-none d-sm-table-cell">Link Quality</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="props.logs == null || props.logs.loading">
                                <td colspan="5" class="text-center">Data is loading...</td>
                            </tr>
                            <tr v-else-if="!props.logs.response.body.length" class="table-info">
                                <td colspan="5"
                                    class="text-center font-weight-bold">No log entries found.</td>
                            </tr>
                            <tr v-else v-for="log in props.logs.response.body"
                                :key="log.identifier + '/' + log.timestamp">
                                <td><ShowDateTimeDisplay :date="new Date(log.timestamp)"/></td>
                                <td><ShowTextDisplay :long="showState(log)" :short="showState(log, true)"/></td>
                                <td><ShowTextDisplay :long="showAction(log)" :short="showAction(log, true)"/></td>
                                <td class="d-none d-sm-table-cell">{{ showBattery(log) }}</td>
                                <td class="d-none d-sm-table-cell">{{ showLinkQuality(log) }}</td>
                            </tr>
                        </tbody>
                        <tfoot v-if="props.logs && props.logs.response.links">
                            <tr>
                                <th>
                                    <button type="button" class="btn btn-primary"
                                            :disabled="!props.logs.hasPrevious"
                                            @click="props.logs.previous">Previous</button>                                            
                                </th>
                                <th colspan="3">Showing {{ props.logs.response.body.length }} results</th>
                                <th>
                                    <button type="button" class="btn btn-primary"
                                            :disabled="!props.logs.hasNext"
                                            @click="props.logs.next">Next</button>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-backdrop show"></div>
</Teleport>
</template>