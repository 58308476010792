<script setup>

import FormField from '@/components/FormField'
import { computed, inject, onMounted, reactive, ref } from 'vue'

const HCP = inject('HCP')
const Users = ref([])
const SelectedUser = ref(null)
let RetrieveUser = null

const userAction = computed(() => SelectedUser.value.create ? 'Create User' : 'Update User')

const validateUser = computed(() => {
    const user = SelectedUser.value

    return /^\w+$/.test(user.user) && (!user.create || user.password.length > 0)
})

const loadUsers = async () => {
    const responseUsers = await HCP.getAllUsers()

    Users.value = responseUsers
}

const newUser = () => {
    SelectedUser.value = reactive({
        create: true,
        user: "",
        password: "",
        isActive: false,
        isSuper: false})
}

const selectUser = async (user) => {
    RetrieveUser = await HCP.getUser(user)

    SelectedUser.value = reactive({create: false, password: "", ...RetrieveUser})
}

const saveUser = async () => {
    const user = SelectedUser.value

    if (user.create) {
        try {
            user.saving = true
            await HCP.createUser(user)
            SelectedUser.value = null
            loadUsers()
            return 'complete'
        } catch (err) {
            user.saving = false
            return 'failure'
        }
    } else {
        try {
            const allStatus = []
            user.saving = true
            if (user.password.length > 0) {
                const status = await HCP.setUserPassword(user.user, user.password)
                allStatus.push(status)
            }
            if (user.isActive != RetrieveUser.isActive) {
                const status = await (
                    user.isActive ?
                    HCP.enableUser(user.user) :
                    HCP.disableUser(user.user))
                allStatus.push(status)
            }
            if (user.isSuper != RetrieveUser.isSuper) {
                const status = await (
                    user.isSuper ?
                    HCP.setSuperUser(user.user) :
                    HCP.unsetSuperUser(user.user))
                allStatus.push(status)
            }
            const failureStatus = allStatus.find((status) => status != 'complete')
            if (failureStatus) {
                user.saving = false
                return failureStatus
            }
            SelectedUser.value = null
            loadUsers()
            return 'complete'
        } catch (err) {
            user.saving = false
            return 'failure'
        }
    }
}

onMounted(async () => {
    await loadUsers()
})

</script>
<template>
    <form v-if="SelectedUser">
        <FormField label="Username" fieldId="user"
                   ref="user" :fieldRO="!SelectedUser.create" fieldPattern="^\w+$"
                   v-model="SelectedUser.user"/>
        <FormField label="Password" fieldId="password"
                   fieldType="password" v-model="SelectedUser.password"/>
        <FormField label="Active" fieldId="isActive"
                   fieldType="checkbox" v-model="SelectedUser.isActive"/>
        <FormField label="Super User" fieldId="isSuper"
                   fieldType="checkbox" v-model="SelectedUser.isSuper"/>
        <button type="button" class="btn btn-success"
                :disabled="SelectedUser.saving || !validateUser" @click="saveUser">{{ userAction }}</button>
    </form>
    <div v-else class="list-group list-group-flush">
        <button type="button" class="list-group-item list-group-item-action list-group-item-primary"
                @click="newUser">Create New User</button>
        <button type="button" class="list-group-item list-group-item-action"
                v-for="user in Users" :key="user" @click="selectUser(user)">{{ user }}</button>
    </div>
</template>