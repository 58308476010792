<script setup>

import { computed, defineProps, defineModel, useTemplateRef } from 'vue'

useTemplateRef('input')

const props = defineProps({
    label: {type: String, required: true},
    fieldId: {type: String, required: true},
    fieldType: {type: String, default: "text"},
    fieldPattern: {type: String},
    fieldRO: {type: Boolean},
    labelSize: {type: Number, default: 2},
    fieldSize: {type: Number, default: 10},
    fieldRef: {type: String}
})

const fieldValue = defineModel({required: true})

const fieldAttr = {
    type: props.fieldType,
    id: props.fieldId,
    pattern: props.fieldPattern,
    readonly: props.fieldRO ? props.fieldRO : undefined,
    ref: props.fieldRef,
}

const inputType = computed(() => {
    switch (props.fieldType) {
    case "checkbox":
    case "radio":
        return "form-check-input"
    default:
        return "form-control"
    }
})

</script>

<template>
    <div class="mb-3">
        <label :for="fieldId" :class="['col-sm-' + labelSize, 'col-form-label']">{{ label }}</label>
        <div :class="['col-sm-' + fieldSize]"> 
            <input v-bind="fieldAttr" :class="inputType" ref="input" v-model="fieldValue"/>
        </div>
    </div>
</template>// form-check-input